
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Selector extends Vue {
	itemId = '';
	platform = 'goedemorgen';

	get isValid() {
		return this.itemId.length > 0;
	}
	go() {
		console.log('go', this);
		this.$router.push({ name: 'slider', params: { id: this.itemId, slide: '0', platform: this.platform } });
	}
}
