import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Selector from "@/views/Selector.vue";
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/selector",
    name: "select",
    component: Selector,
  },
  {
    path: "/why/:id/",
    redirect: (to) => {
      const { params } = to;
      return {
        name: "slider",
        params: { ...params, platform: "why", slide: "1" },
      };
    },
  },
  {
    path: "/gms/:id/",
    redirect: (to) => {
      const { params } = to;
      return {
        name: "slider",
        params: { ...params, platform: "gms", slide: "1" },
      };
    },
  },
  {
    path: "/dno/:id/",
    redirect: (to) => {
      const { params } = to;
      return {
        name: "slider",
        params: { ...params, platform: "dno", slide: "1" },
      };
    },
  },
  {
    path: "/:platform/:id/:slide",
    name: "slider",
    component: () => import("../views/Slider.vue"),
  },
  {
    path: "/:catchAll(.*)",
    redirect: { name: "select" },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
